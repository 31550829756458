import React from "react";
import Circleimage from "../assets/images/Circlecustomize.svg";
import leftsideimg from "../assets/images/blog-left-side.jpeg";
import rightblogimg1st from "../assets/images/Rightblogimg1st.jpeg";
import arrow from "../assets/images/Arrowappointmentbutton.svg";
import Hartpic from "../assets/images/heratpic.jpeg";
import medicine from "../assets/images/medicine.jpeg";
import { HiArrowUpRight } from "react-icons/hi2";
import { IconContext } from "react-icons";

const Blogs = () => {
  return (
    <>
      <section className="blog-section pb-5" id="blogs">
        <div className="text-center">
          <div className="position-relative">
            <img
              src={Circleimage}
              alt=""
              className="position-absolute circle-image"
            />
          </div>
          <div>
            <h1 className="blogheading m-0">Our Latest Blogs</h1>
          </div>

          <div>
            <h6 className="blogdocname">By Dr.Zunair Akbar</h6>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-4">
            <div className="col-lg-6 blog-left-section">
              <div className="paddingofleftsectionblog">
                <div>
                  <h2 className="blogheaderleftside">
                    Understanding Heart Disease: A Comprehensive Overview
                  </h2>
                </div>
                <div>
                  <p className="blogdewscriptioofleftside">
                    In this blog post, we delve into the various aspects of
                    heart disease, from common risk factors to preventive
                    measures. We'll explore how lifestyle choices, such as diet
                    and exercise, can significantly impact heart health.....
                  </p>
                </div>
                <div>
                  <div className="position-relative">
                    <img
                      src={leftsideimg}
                      alt=""
                      className="blogleftimgsection img-fluid"
                    />
                    <button className="btn btn-outline position-absolute text-white  border border-light border-1 rounded-5 learmorebuttonblogsection">
                      Learn More <img src={arrow} alt="" className="ms-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div className="firstrowofcol6blogs d-flex mb-4">
                  <div>
                    <img
                      src={rightblogimg1st}
                      alt=""
                      className="rightbobsetoionimg img-fluid"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="nameofshortsection">Topic</p>
                    <p className="mainhighloghtpointoffirtssectionofcolblog mb-1">
                      Latest Innovations in Cardiac Treatment What Patients Need
                      to Know
                    </p>
                    <p className="nameofshortsection">
                      In this blog post, we delve into the various aspects of
                      heart disease, from common.....
                    </p>
                  </div>
                </div>
                <div className="firstrowofcol6blogs d-flex mb-3">
                  <div className="me-2">
                    <img
                      src={Hartpic}
                      alt=""
                      className="rightbobsetoionimg img-fluid"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="nameofshortsection">Topic</p>
                    <p className="mainhighloghtpointoffirtssectionofcolblog mb-1">
                      Latest Innovations in Cardiac Treatment What Patients Need
                      to Know
                    </p>
                    <p className="nameofshortsection">
                      In this blog post, we delve into the various aspects of
                      heart disease, from common.....
                    </p>
                  </div>
                </div>
                <div className="firstrowofcol6blogs d-flex">
                  <div className="me-2">
                    <img
                      src={medicine}
                      alt=""
                      className="rightbobsetoionimg img-fluid"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="nameofshortsection">Topic</p>
                    <p className="mainhighloghtpointoffirtssectionofcolblog mb-1">
                      Latest Innovations in Cardiac Treatment What Patients Need
                      to Know
                    </p>
                    <p className="nameofshortsection">
                      In this blog post, we delve into the various aspects of
                      heart disease, from common.....
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
