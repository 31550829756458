import React from "react";
import docimg from "../assets/images/toppicofdoc.jpg";
import appointmenticon from "../assets/images/Group.svg";
import contactus from "../assets/images/contactus.svg";
import experince from "../assets/images/Eperrince.svg";
import people from "../assets/images/poepole.svg";
import star from "../assets/images/starinabout.svg";
import { Link } from "react-router-dom";
const Aboutus = () => {
  return (
    <section className="aboutussection" id="about">
      <div className="container d-flex justify-content-center p-0">
        <div className="row w-100 gx-lg-5 gy-5">
          <div className="col-xl-5">
            <div className="position-relative">
              <img src={docimg} alt="" className="img-fluid docimgtagabout" />
              <img
                src={docimg}
                alt=""
                className="position-absolute img-fluid smalldocimg "
              />
              <div className="row w-100 move-to-up">
                <div className="col-12 p-0">
                  <div className="aboutussecondorwchngetomobile">
                    <div className="d-flex justify-content-around">
                      <div>
                        <p className="aboutusreviewblog m-0 mb-1">
                          <img src={experince} alt="" /> Experience
                        </p>
                        <p className="m-0 aboutusreview">5 Years</p>
                      </div>
                      <div>
                        <p className="aboutusreviewblog m-0 mb-1">
                          <img src={people} alt="" /> Satisfied Patients
                        </p>
                        <p className="m-0 aboutusreview">
                          100% <sub className="aboutusreviewblog">(2348)</sub>
                        </p>
                      </div>
                      <div>
                        <p className="aboutusreviewblog m-0 mb-1">
                          <img src={star} alt="" /> Patients Review
                        </p>
                        <p className="m-0 aboutusreview">
                          4.9 <sub className="aboutusreviewblog">/5.0</sub>
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <div>
              <h1 className="blogheading mb-3">About Me</h1>
            </div>
            <div>
              <p className="Aboutuspagedescription m-0 mb-4">Description :</p>
              <p className="Aboutuspageparagrapgh m-0 mb-1">
                Dr. Zunair Akbar Mayo, a General and Laparoscopic surgeon,
                graduated in 2015 from the College of Physicians and Surgeons,
                Pakistan. With expertise in minimally invasive procedures, Dr.
                Mayo is dedicated to providing high-quality surgical care for
                his patients. he prioritizes promptness alongside personalized
                care, leading to a remarkable 100% satisfaction rate among his
                2348 patients. Dr. Zunair Akbar expertise, compassion, and
                commitment to cutting-edge technology define his mission to
                elevate health and patient well-being.
              </p>
            </div>
            <div className="checkboxdefine mt-4">
              <div className="d-flex mb-2">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked
                  className="checkboxes"
                />
                <p className="m-0 ms-3">Trustcare is health care, but easy</p>
              </div>
              <div className="d-flex">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked
                  className="checkboxes"
                />
                <p className="m-0 ms-3">Top-searched specialties</p>
              </div>
            </div>
            <div className="mt-4">
              <Link
                className="btn apointmentinabout herobutton mt-3"
                to={"form"}
              >
                <img
                  src={appointmenticon}
                  alt=""
                  className="mb-1 me-2 appointment-png"
                />{" "}
                Get Appointment
              </Link>
              <a href="#contactus">
                <button className="btn contactusinabout ms-4 mt-3">
                  <img src={contactus} alt="" className="me-2 contact-us-svg" />
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100 take-to-upside">
        <div className="col-4"></div>
        <div className="col-8 p-0 z-1">
          <div className="aboutussecondorw">
            <div className="d-flex justify-content-around">
              <div>
                <p className="aboutusreviewblog m-0 mb-1">
                  <img src={experince} alt="" /> Experience
                </p>
                <p className="m-0 aboutusreview">5 Years</p>
              </div>
              <div>
                <p className="aboutusreviewblog m-0 mb-1">
                  <img src={people} alt="" /> Satisfied Patients
                </p>
                <p className="m-0 aboutusreview">
                  100% <sub className="aboutusreviewblog">(2348)</sub>
                </p>
              </div>
              <div>
                <p className="aboutusreviewblog m-0 mb-1">
                  <img src={star} alt="" /> Patients Review
                </p>
                <p className="m-0 aboutusreview">
                  4.9 <sub className="aboutusreviewblog">/5.0</sub>
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
