import React, { useState } from "react";
import logo from "../assets/images/Frame.png";
import querrybutton from "../assets/images/Vector.svg";
const Navbar = () => {
  const [open, setOpen] = useState(true);
  const statechanger = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = useState(false);
  const statechanger1 = () => {
    setOpen1(!open1);
  };
  return (
    <>
      <div
        className={
          open
            ? "container-fluid backgroundclrofnavbar borderbottomclr d-class"
            : "container-fluid backgroundclrofnavbar borderbottomclr d-non-class"
        }
      >
        <header className="pt-3 pb-2 ">
          <div className="d-flex flex-row justify-content-between mx-5 ">
            <div className="col-md-3 mb-2 mb-md-0 mb-sm-0 ">
              <a
                href="/"
                className="d-inline-flex link-body-emphasis text-decoration-none"
              >
                <img src={logo} alt="" className="doctorpersonallogo" />
              </a>
            </div>
            <div className="mqforshortscreen">
              <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 figmacssofnavbar">
                <li>
                  <a href="#home" className="nav-link px-3 clroftextinnavbar">
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#services"
                    className="nav-link link-secondary px-3 clroftextinnavbar"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    className="nav-link link-secondary px-3 clroftextinnavbar"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#blogs"
                    className="nav-link link-secondary px-3 clroftextinnavbar"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-2 mb-md-0 mb-sm-0 text-end mqforshortscreen">
              <a href="#contactus">
                <button className="btn rounded-5 navbarbuttonwidth">
                  Contact us
                </button>
              </a>
            </div>
            <div className="hideicon">
              <a
                onClick={statechanger1}
                href="#"
                className="shortacreenqueeruicon border border-2 rounded-circle d-flex align-items-center justify-content-center"
              >
                <img src={querrybutton} alt="" className="Nav-icon" />
              </a>
            </div>
          </div>
        </header>
      </div>
      <div
        className={
          open1
            ? "bg-black opacity-75 position-absolute w-100 h-100 d-class top-0 z-2"
            : "bg-black opacity-75 position-absolute w-100 h-100 z-2 d-non-class top-0"
        }
      >
        <ul className=" liststyletypenone text-white d-flex flex-column align-items-center  w-100 h-100">
          <div className="d-flex justify-content-end w-100 me-5 mt-3">
            <div className="me-4">
              {" "}
              <a href="#" onClick={statechanger1}>
                <img
                  src={querrybutton}
                  alt=""
                  className="Nav-icon nav-icon-white"
                />
              </a>
            </div>
          </div>
          <div>
            <li className="my-4">
              <a
                href="#home"
                className="text-decoration-none text-white"
                onClick={statechanger1}
              >
                Home
              </a>
            </li>
            <li className="my-4">
              <a
                href="#services"
                className="text-decoration-none text-white"
                onClick={statechanger1}
              >
                Services
              </a>
            </li>
            <li className="my-4">
              <a
                href="#about"
                className="text-decoration-none text-white"
                onClick={statechanger1}
              >
                About
              </a>
            </li>
            <li className="my-4">
              <a
                href="#blogs"
                className="text-decoration-none text-white"
                onClick={statechanger1}
              >
                Blog
              </a>
            </li>
          </div>
        </ul>
      </div>{" "}
    </>
  );
};

export default Navbar;
