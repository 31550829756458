import React from "react";
import wave from "../assets/images/Fromwave.svg";
import logo from "../assets/images/Frame.png";
import { ContactUs } from "./ContactUs";
const Form = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 1970; year <= currentYear; year++) {
    years.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  return (
    <>
      <div className="header-div">
        <div className="container d-flex align-items-center z-3">
          <div className="">
            <img src={logo} alt="" className="doc-logo-form" />
          </div>
          <div className="ms-auto">
            <p className="form-heding m-0">0301-8770084</p>
            <p className="form-heding m-0">Drzunairakbar@gamil.com</p>
          </div>
        </div>{" "}
      </div>{" "}
      <div className="overflow-x-hidden">
        <img src={wave} alt="" className="wave-postion" />
      </div>
      <ContactUs />
    </>
  );
};

export default Form;
