import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const ContactUs = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 1970; year <= currentYear; year++) {
    years.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_qk6p9rg", "template_8fi7tli", form.current, {
        publicKey: "xyvtC3tzOhFETx9gz",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="container">
      <div className="row w-100">
        <h1 className="text-center form-hedin1g mb-5">
          Dr. Zunair Akbar Appointment Form
        </h1>
        <div className="col-md-6 d-flex flex-column justify-content-end">
          <label htmlFor="" className="label-for-form mb-2">
            Name
          </label>
          <input
            type="text"
            placeholder="Enter Full Name"
            className="form-control input-border-half mb-4"
            name="User_name"
            required
          />
        </div>
        <div className="col-md-6 d-flex flex-column">
          <label htmlFor="" className="label-for-form mb-2">
            Email
          </label>
          <input
            type="email"
            placeholder="example@gmail.com"
            className="form-control input-border-half mb-4"
            required
            name="user_email"
          />
        </div>
      </div>
      <div className="row w-100">
        <div className="col-md-6">
          <label htmlFor="" className="label-for-form mb-2">
            DOB
          </label>
          <div className="d-flex justify-content-between">
            <select id="day" name="day" className="iput-border-third-half me-2">
              <option value="Day">Day</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
            </select>
            <select
              id="month"
              name="month"
              className="iput-border-third-half me-2"
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <select
              id="year"
              name="year"
              className="iput-border-third-half me-2"
            >
              {years}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <label
            htmlFor=""
            className="label-for-form mb-2 mt-lg-0 mt-md-3 mt-sm-3"
          >
            Gender
          </label>
          <div className="d-flex flex-column mb-4">
            <div className="">
              <input type="radio" id="Male" name="Gender" value="Male" />
              <label for="Male"> Male</label>
              <br />
              <input type="radio" id="Female" name="Gender" value="Female" />
              <label for="Female">Female</label>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100">
        <div className="col-md-6">
          <label htmlFor="" className="label-for-form mb-2">
            Phone Number
          </label>
          <div className="d-flex justify-content-between mb-4">
            <input
              type="text"
              className="form-control input-border-half mb-4 me-2"
              required
              name="phonenumber"
            />
          </div>
        </div>{" "}
        <div className="col-md-6 d-flex flex-column">
          <label htmlFor="" className="label-for-form mb-2">
            Address
          </label>
          <input
            type="text"
            placeholder="Addres"
            className="form-control input-border-half mb-4"
            required
            name="address"
          />
        </div>
      </div>
      <div className="row w-100">
        <div className="col-6 d-flex flex-column">
          <label htmlFor="" className="label-for-form mb-2">
            Appointment date{" "}
          </label>
          <input
            className="form-control input-border-half mb-4"
            type="date"
            pattern="\d{2}/\d{2}/\d{4}"
            placeholder="DD/MM/YYYY (Optional)"
            name="date"
          />
        </div>
        <div className="col-6 d-flex flex-column mb-4">
          <label htmlFor="" className="label-for-form mb-2">
            Appointment Time{" "}
          </label>
          <input
            type="time"
            placeholder="11:00PM (Optional)"
            className="form-control input-border-half mb-4"
            name="time"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button type="submit" className="btn btn-primary" value="Send">
          Submit
        </button>
      </div>
    </form>
  );
};
