import React from "react";
import appointmenticon from "../assets/images/Group.svg";
import arrow from "../assets/images/Arrowappointmentbutton.svg";
import { Link } from "react-router-dom";
const Appointment = () => {
  return (
    <section className="appointment-section">
      <div className="container-fluid d-flex flex-column align-items-center justify-content-center">
        <h2 className="text-center apoointmentfigmacss">
          Get Appointment On your shedule for <br /> better treatment
        </h2>

        <Link
          className="appintmentbuttoninwholewebsite mt-4 mb-5 herobutton text-decoration-none"
          to={"form"}
        >
          <div className=" d-flex justify-content-center align-items-center">
            <div>
              <img
                src={appointmenticon}
                alt=""
                className="me-3 apoointimage appointment-png"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p className="m-0 fontfamilofappointmentbutton">
                Get Appointment
              </p>
            </div>
            <div>
              <img
                src={arrow}
                alt=""
                className="ms-3 apoointimage appointment-png"
              />
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Appointment;
