import React from "react";
import arrow from "../assets/images/Arrowappointmentbutton.svg";
import star from "../assets/images/Star.svg";
import Reviewcarousal from "./Reviewcarousal";
const Review = () => {
  return (
    <section className="review-section my-5">
      <div className="transbox text-white">
        <div className="row w-100 d-flex justify-content-end container">
          <div className="rating d-flex justify-content-center align-items-center">
            <div className="d-flex mt-3">
              <div className="me-2">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <p className="p-0 docrating">4.9</p>
                </div>
                <div className="mb-3">
                  <sub className="fullrating">/5.0</sub>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 gapeofcariusal gy-4">
          <div className="col-lg-4">
            <div className="review-section-left-side w-100">
              <h1 className="review-font m-0">Review</h1>
              <h6 className="pateinrtreview m-0">Our patients review</h6>
              <p className="descriptiopnofreview">
                Dr. Zunair's professionalism and wealth of experience is truly
                exceptional. His clear communication, surgical skill are
                compassionate and comforting. I wholeheartedly recommend him.
              </p>
            </div>
          </div>
          <div className="col-lg-7 p-0">
            <div className="carousal-section d-flex align-items-center ms-3 ms-lg-0">
              <Reviewcarousal />
            </div>
          </div>
        </div>
        <div className="row w-100">
          <div className="reviewrating">
            <div className="d-flex align-items-center">
              <div className="mb-4 me-2">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <div>
                  <h6 className="satisfiedpateinrt">Satisfied Patients</h6>
                </div>
                <div className="d-flex flex-row mt-3">
                  <sup>
                    <h1 className="percnetagepatient">100%</h1>
                  </sup>
                  <sub>
                    <h6 className="mt-1 percnetagepatientinno">(2348)</h6>
                  </sub>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Review;
