import React from "react";
import underline from "../assets/images/Underline.svg";
import arrow from "../assets/images/Arrow.svg";
import Docimg from "../assets/images/zunairakbar-removebg-preview.png";
import appointmenticon from "../assets/images/Group.svg";
import Curveshape from "../assets/images/Curveshape.svg";
import { HiArrowLongRight } from "react-icons/hi2";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

const Herosec = () => {
  return (
    <>
      <section
        className="container-fluid w-100 herosectiondiv p-0 overflow-x-hidden overflow-y-hidden"
        id="home"
      >
        <div className="row ps-3">
          <div className="col-xxl-7 container d-xxl-flex mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-3 flex-column position-relative left-side-div mb-2">
            <div className="doctornamemq">
              <div className="">
                <h1 className="fullwelcome">Welcome to</h1>
              </div>
              <div className="d-flex">
                <h1
                  className="welcomedoc fullwelcome ms-1 position-relative"
                  style={{ letterSpacing: "0.2px" }}
                >
                  Dr. Zunair Akbar
                  <img
                    src={underline}
                    alt=""
                    className="underlinepng underlinecss position-absolute"
                  />
                </h1>
              </div>
            </div>
            <div>
              <div className="welcomedoc d-flex mt-5">
                <div>
                  <h1 className="fullwelcome">Laparoscopic</h1>
                </div>
                <div className="position-relative mt-4">
                  <p className="definingofspecialization ms-4 position-absolute specialitssmallsizwe">
                    specialist
                  </p>
                  <img
                    src={arrow}
                    alt="Arrow"
                    className="position-absolute aroowcss"
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <p className="docdiscription">
                  At
                  <span className="welcomedoc weighhtofname mx-2">
                    Dr. Zunair Akbar Mayo
                  </span>
                  practice, we are Offering advanced laparoscopic
                  cholecystectomy services for the removal of gallstones and
                  gallbladder-related issues. Our minimally invasive approach
                  ensures faster recovery, reduced scarring, and lower risk of
                  complications. Trust our expert surgeons and state-of-the-art
                  technology for optimal outcomes and improved patient
                  satisfaction.
                </p>
              </div>
              <div>
                <Link className="btn herobutton" to={"/form"}>
                  <img
                    src={appointmenticon}
                    className="img-fluid mb-1 me-2 appointment-png"
                  />{" "}
                  Book An Appointment
                </Link>
                <a href="#blogs">
                  <button className="btn btn-outline-light lerasnmorebuttoninherosection ms-md-4 ">
                    Learn More
                    <IconContext.Provider
                      value={{ className: "learmorearrow ms-2" }}
                    >
                      <HiArrowLongRight />
                    </IconContext.Provider>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xxl-5 p-0 overflow-x-hidden overflow-y-hidden">
            <div className="circleofhersection position-relative right-side-div">
              <img
                src={Docimg}
                alt=""
                className="z-1 position-absolute docimg img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="col-12 position-relative z-1 w-100">
          <img
            src={Curveshape}
            alt=""
            className="curveshapecss w-100 position-absolute"
          />
        </div>
      </section>
    </>
  );
};

export default Herosec;
