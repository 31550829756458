import React from "react";
import invertedcooma from "../assets/images/invertedcomm.svg";
import Profile from "../assets/images/Profile.png";
import review1 from "../assets/images/review3.jpg";
import review2 from "../assets/images/review1.jpg";
import review3 from "../assets/images/review2.jpg";
import star from "../assets/images/Star.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
const Reviewcarousal = () => {
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        modules={[Navigation]}
        className="mySwiper"
        navigation={true}
      >
        <SwiperSlide>
          <div className="">
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh text-capitalize">
                Dr. Zunair's professionalism and wealth of experience is truly
                exceptional.
              </p>
            </div>
            <div className="d-flex align-items-center mt-4">
              <div>
                <img src={review1} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh text-capitalize">
                One of the finest surgeons in Lahore, renowned for their
                exceptional skills, and dedication to patient well-being .
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={review2} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh text-capitalize">
                "Mashallah, a competent surgeon with a stellar reputation for
                delivering outstanding surgical expertise
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={review3} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh">
                His clear communication, surgical skill are compassionate and
                comforting. I wholeheartedly recommend him.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={Profile} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh">
                Pellentesque etiam blandit in tincidunt at donec. Eget ipsum
                dignissim placerat nisi, adipiscing mauris non.{" "}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={Profile} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh">
                Pellentesque etiam blandit in tincidunt at donec. Eget ipsum
                dignissim placerat nisi, adipiscing mauris non.{" "}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={Profile} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh">
                Pellentesque etiam blandit in tincidunt at donec. Eget ipsum
                dignissim placerat nisi, adipiscing mauris non.{" "}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={Profile} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh">
                Pellentesque etiam blandit in tincidunt at donec. Eget ipsum
                dignissim placerat nisi, adipiscing mauris non.{" "}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={Profile} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="mb-4">
              <img src={invertedcooma} alt="" className="invertedcomma" />
            </div>
            <div className="mb-4">
              <p className="caroudsalparagrapgh">
                Pellentesque etiam blandit in tincidunt at donec. Eget ipsum
                dignissim placerat nisi, adipiscing mauris non.{" "}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={Profile} alt="" className="profileimg" />
              </div>
              <div className="ms-2">
                <h4 className="m-0 fontstyleofcarossal">Janne Hooper</h4>
              </div>
              <div className="ms-auto me-2 mb-1">
                <img src={star} alt="" className="starsizzze" />
              </div>
              <div>
                <h4 className="m-0 p-1 fontstyleofcarossal">4.3</h4>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Reviewcarousal;
