import React from "react";
import angiography from "../assets/images/angiography.svg";
import hertpulse from "../assets/images/hertpulse.svg";
import balloon from "../assets/images/balloon.svg";
import gameicon from "../assets/images/game.svg";
import { Link } from "react-router-dom";
const Services = () => {
  return (
    <section className="container-fluid p-0" id="services">
      <div className="main-services-sec">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div>
            <h1 className="text-white specialfont">Services</h1>
          </div>
          <div>
            <p className="kronafont">Our Specialisation Are In </p>
          </div>
        </div>
        <div className="container definingheightofherosec">
          <div className="row gap-5 d-flex justify-content-center">
            <div className="col-3 cardofspeaclist d-flex flex-column align-items-center justify-content-center">
              <div className="rounded-circle clrofangiography d-flex justify-content-center align-items-center">
                <div>
                  <img src={angiography} alt="" />
                </div>
              </div>
              <div className="">
                <h5 className="angiographyhesding pt-3">
                  Laparoscopic surgeries
                </h5>
              </div>
              <div className="">
                <p className="descriptionofangiography pt-3">
                  We provide advanced laparoscopic cancer surgeries for precise
                  and minimally invasive treatment.
                </p>
              </div>
              <div className="hidebuttoninnormalmode">
                <Link to={"/form"}>
                  <button className="btn appointmnetbuttoninservices text-white">
                    Appointment
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-3 cardofspeaclist d-flex flex-column align-items-center justify-content-center">
              <div className="rounded-circle clrofhertapulse d-flex justify-content-center align-items-center">
                <div>
                  <img src={hertpulse} alt="" />
                </div>
              </div>
              <div className="">
                <h5 className="angiographyhesding pt-3">Lap. Hernia Surgery</h5>
              </div>
              <div className="">
                <p className="descriptionofangiography pt-3">
                  We perform laparoscopic hernia surgery for efficient and
                  minimally invasive repair.
                </p>
              </div>
              <div className="hidebuttoninnormalmode">
                <Link to={"/form"}>
                  <button className="btn appointmnetbuttoninservices text-white">
                    Appointment
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-3 cardofspeaclist d-flex flex-column align-items-center justify-content-center">
              <div className="rounded-circle clrofballoon d-flex justify-content-center align-items-center">
                <div>
                  <img src={balloon} alt="" />
                </div>
              </div>
              <div className="">
                <h5 className="angiographyhesding pt-3">
                  Breast Cancer Surgery
                </h5>
              </div>
              <div className="">
                <p className="descriptionofangiography pt-3">
                  We specialize in comprehensive breast cancer surgeries,
                  providing personalized care
                </p>
              </div>
              <div className="hidebuttoninnormalmode">
                <Link to={"/form"}>
                  <button className="btn appointmnetbuttoninservices text-white">
                    Appointment
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-3 cardofspeaclist d-flex flex-column align-items-center justify-content-center">
              <div className="rounded-circle clrofgame d-flex justify-content-center align-items-center">
                <div>
                  <img src={gameicon} alt="" />
                </div>
              </div>
              <div className="">
                <h5 className="angiographyhesding pt-3">
                  lap. cholecystectomy{" "}
                </h5>
              </div>
              <div className="">
                <p className="descriptionofangiography pt-3">
                  cholecystectomy, ensuring efficient gallbladder removal with
                  minimal scarring and faster recovery.
                </p>
              </div>
              <div className="hidebuttoninnormalmode">
                <Link to={"/form"}>
                  <button className="btn appointmnetbuttoninservices text-white">
                    Appointment
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
