import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import first from "../assets/images/firstslide.jpg";
import second from "../assets/images/2nd.jpg";
import third from "../assets/images/third.jpg";
import foyrth from "../assets/images/fourh.jpg";
import fifth from "../assets/images/fifth.jpg";
import sixth from "../assets/images/sixth.jpg";
import { HiArrowSmallRight } from "react-icons/hi2";
import { HiArrowSmallLeft } from "react-icons/hi2";
import { IconContext } from "react-icons";

class Example extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 1,
    showNavigation: true,
    config: config.gentle,
  };

  slides = [
    {
      key: uuidv4(),
      content: (
        <div className="bg-white shadow-lg p-3 border rounded-3 carousal-div-targetd-img">
          <img
            src={first}
            alt="1"
            className="img-fluid w-100 object-fit-cover "
          />
          <p className="trement-hedeing pt-4">Laparoscopic surgeries</p>
          <p className="trement-para">
            We provide advanced laparoscopic cancer surgeries for precise and
            minimally invasive treatment.
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="bg-white shadow-lg p-3 carousal-div-targetd-img">
          <img src={second} alt="1" className="img-fluid" />
          <p className="trement-hedeing pt-4">Lap. Hernia Surgery</p>
          <p className="trement-para">
            We perform laparoscopic hernia surgery for efficient and minimally
            invasive repair.
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="bg-white shadow-lg p-3 carousal-div-targetd-img">
          <img
            src={third}
            alt="1"
            className="img-fluid w-100 object-fit-fill"
          />
          <p className="trement-hedeing pt-4">Lap. Cholecystectomy</p>
          <p className="trement-para">
            We perform laparoscopic hernia surgery for efficient and minimally
            invasive repair.
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="bg-white shadow-lg p-3 carousal-div-targetd-img">
          <img
            src={foyrth}
            alt="1"
            className="img-fluid w-100 object-fit-fill"
          />
          <p className="trement-hedeing pt-4">Lap. Cholecystectomy </p>
          <p className="trement-para">
            We perform laparoscopic hernia surgery for efficient and minimally
            invasive repair.
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="bg-white shadow-lg p-3 carousal-div-targetd-img">
          <img
            src={fifth}
            alt="1"
            className="img-fluid w-100 object-fit-fill"
          />
          <p className="trement-hedeing pt-4">Lap. Hernia Surgery</p>
          <p className="trement-para">
            We perform laparoscopic hernia surgery for efficient and minimally
            invasive repair.
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="bg-white shadow-lg p-3 carousal-div-targetd-img">
          <img
            src={sixth}
            alt="1"
            className="img-fluid w-100 object-fit-fill"
          />
          <p className="trement-hedeing pt-4">Lap. Hernia Surgery</p>
          <p className="trement-para">
            We perform laparoscopic hernia surgery for efficient and minimally
            invasive repair.
          </p>
        </div>
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  componentDidMount() {
    this.updateOffsetRadius();
    window.addEventListener("resize", this.updateOffsetRadius);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateOffsetRadius);
  }

  updateOffsetRadius = () => {
    let offsetRadius = 1;
    if (window.innerWidth <= 400) {
      offsetRadius = 0;
    } else if (window.innerWidth <= 768) {
      offsetRadius = 1;
    } else {
      offsetRadius = 2;
    }
    this.setState({ offsetRadius });
  };

  render() {
    return (
      <div
        style={{ width: "100%", height: "500px", margin: "0 auto" }}
        className="carousak"
      >
        <Carousel
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
        <div
          style={{
            margin: "0 auto",
            width: "10%%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            onClick={() => {
              this.setState((prevState) => ({
                goToSlide: prevState.goToSlide - 1,
              }));
            }}
            style={{ cursor: "pointer" }}
            className="border border-1 rounded-pill circle-height d-flex align-items-center justify-content-center me-2"
          >
            <IconContext.Provider
              value={{ className: "treamnet-section-button-arrow" }}
            >
              <HiArrowSmallLeft />
            </IconContext.Provider>
          </div>
          <div
            className="border border-1 rounded-pill circle-height d-flex align-items-center justify-content-center ms-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState((prevState) => ({
                goToSlide: prevState.goToSlide + 1,
              }));
            }}
          >
            <IconContext.Provider
              value={{ className: "treamnet-section-button-arrow" }}
            >
              <HiArrowSmallRight />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default Example;
