import React from "react";
import Circleimage from "../assets/images/Circlecustomize.svg";
import Carousalsection from "./Carousalsection";
import Example from "./Carousal_threedy";

const Treatment = () => {
  return (
    <section className="mb-5">
      <div className="text-center mb-4">
        <div className="position-relative">
          <img
            src={Circleimage}
            alt=""
            className="position-absolute circle-image"
          />
        </div>
        <div>
          <h1 className="blogheading m-0">Our Latest Treatment</h1>
        </div>

        <div>
          <h6 className="blogdocname">By Dr.Zunair Akbar</h6>
        </div>
      </div>
      <Example />
    </section>
  );
};

export default Treatment;
