import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Navbar from "./components/Navbar";
import Herosec from "./components/Herosec";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Appointment from "./components/Appointment";
import Review from "./components/Review";
import Blogs from "./components/Blogs";
import Aboutus from "./components/Aboutus";
import Treatment from "./components/Treatment";
function App() {
  return (
    <>
      <div className="main-container">
        <Navbar />
        <Herosec />
        <Services />
      </div>
      <Aboutus />
      <Treatment />
      <Blogs />
      <Review />
      <Appointment />
      <Footer />
    </>
  );
}

export default App;
