import React from "react";
import logo from "../assets/images/Frame.png";
const Footer = () => {
  return (
    <footer className="">
      <div className="container">
        <hr className="mb-5" />
        <div className="row">
          <div className="col-md-6 text-md-start text-center">
            <div>
              <a
                href="/"
                className="d-inline-flex link-body-emphasis text-decoration-none"
              >
                <img src={logo} alt="" className="doctorpersonallogo" />
              </a>
            </div>
            <div>
              <p className="foterparagragh">
                At Dr. Zunair Akbar Mayo practice, we are Offering advanced
                laparoscopic cholecystectomy services for the removal of
                gallstones and gallbladder-related issues.
              </p>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="d-flex justify-content-md-around flex-md-row flex-column text-md-start text-center">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="me-md-5">
                <h6 className="footerheading py-2">Services</h6>
                <a
                  href=""
                  className="link-dark link-underline link-underline-opacity-0"
                >
                  <p>Laparoscopic surgeries</p>
                </a>
                <a
                  href=""
                  className="link-dark link-underline link-underline-opacity-0"
                >
                  <p>Lap. Hernia Surgery </p>
                </a>
                <a
                  href=""
                  className="link-dark link-underline link-underline-opacity-0"
                >
                  <p>Breast Cancer Surgery</p>
                </a>
                <a
                  href=""
                  className="link-dark link-underline link-underline-opacity-0"
                >
                  <p>lap. cholecystectomy</p>
                </a>
              </div>
              <section className="me-md-5" id="contactus">
                <h6 className="footerheading py-2">Contact Us</h6>
                <p className="m-0 fs-6">
                  Toll free : +1234 567 893
                  <p className="fw-lighter">(8AM to 8Pm IST)</p>
                </p>
                <p className="fs-6">Email : Drzunairakbar@gamil.com</p>
              </section>
              <div>
                <h6 className="footerheading py-2">Follow Us</h6>
                <a
                  href="https://www.facebook.com/profile.php?id=100072226830080&mibextid=LQQJ4d"
                  className="link-dark link-underline link-underline-opacity-0"
                >
                  {" "}
                  <p>Facebook</p>
                </a>
                <a
                  href=""
                  className="link-dark link-underline link-underline-opacity-0 disbale-link"
                  disabled
                >
                  {" "}
                  <p>Instagram</p>
                </a>
                <a
                  href=""
                  className="link-dark link-underline link-underline-opacity-0 disbale-link"
                >
                  {" "}
                  <p>LinkedIn</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
